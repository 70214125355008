jQuery(function(){
	initTruncateTogglers('.truncate-toggle');

	// Floating form labels
	const floatingLabelInputs = document.querySelectorAll('.float-label__input');
	initFloatingLabelInputs(floatingLabelInputs);

	// Videos
	const videos = document.querySelectorAll('.video--play-btn, .video-play');
	videos.forEach((el, index, array) => {
		const isPlayButton = el.classList.contains('video-play'),
			toggleClass = isPlayButton ? 'video-play--stop' : 'hidden',
			poster = document.querySelector(el.getAttribute('data-poster')),
			videoWrapper = document.querySelector(el.getAttribute('data-wrapper'));
		
		const type = el.dataset.type;

		if(type == 'youtube') {
			const iframe = isPlayButton ? document.querySelector(el.getAttribute('href')) : el.querySelector('iframe');
			
			if(!iframe) return;

			const src = iframe.getAttribute('src'),
					url = new URL(src);

			const onYoutubeClick = (e) => {
				e.preventDefault();
				const isPlaying = url.searchParams.get('autoplay') == 1;

				if(isPlayButton) {
					if(isPlaying) {
						url.searchParams.set('autoplay', 0);
						el.classList.remove('hidden');
						el.classList.remove(toggleClass);
						if(poster) {
							poster.classList.remove('video-poster--hidden');
						}
						if(videoWrapper) {
							videoWrapper.classList.remove('video--playing');
						}
					}
					else {
						url.searchParams.set('autoplay', 1);
						el.classList.add('hidden');
						el.classList.add(toggleClass);
						if(poster) {
							poster.classList.add('video-poster--hidden');
						}
						if(videoWrapper) {
							videoWrapper.classList.add('video--playing');
						}
					}
					iframe.setAttribute('src', url.href);
				}
				else {
					url.searchParams.set('autoplay', 1);
					el.classList.add('hidden', 'inactive');
					iframe.setAttribute('src', url.href);

					if(poster) {
						poster.classList.add('video-poster--hidden');
					}

					el.removeEventListener('click', onYoutubeClick);
				}
			}
			el.addEventListener('click', onYoutubeClick);
		}
		else if(type == 'video' || type == 'asset') {
			const video = isPlayButton ? document.querySelector(el.getAttribute('href')) : el.querySelector('video');	
			
			if(!video) return;

			el.addEventListener('click', (e) => {
				e.preventDefault();
				if (video.paused) {
					video.play();
					el.classList.add(toggleClass);
				} else {
					video.pause();
					el.classList.remove(toggleClass);
				}

				video.addEventListener('pause', (event) => {
					el.classList.remove(toggleClass);
				});
			});
		}
	});

	
	/* Resize end / scroll end */
	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function initTruncateTogglers(selector) {
	const truncateTogglers = document.querySelectorAll(selector);
	truncateTogglers.forEach((toggle, index, array) => {
		const target = document.querySelector(toggle.getAttribute('href'));
		if(target) {
			showHideTruncateToggle(target, toggle);

			window.addEventListener('resize', (e) => {
				showHideTruncateToggle(target, toggle);
			});
			toggle.addEventListener('click', (e) => {
				e.preventDefault();
				target.classList.remove('truncate');
				toggle.classList.add('content-hidden');
			});
		}
	});
}

function initFloatingLabelInputs(inputs) {
	inputs.forEach(function(input, index, array) {
		setFilledFloatingLabelInputs(input);
		input.addEventListener('blur', (e) => {
			setFilledFloatingLabelInputs(input);
		});
	});
}

function setFilledFloatingLabelInputs(input) {
	let val = input.value;
	if(val == '' || val.length == 0) {
		input.classList.remove('float-label__input--filled');
	}
	else {
		input.classList.add('float-label__input--filled');
	}
};

function showHideTruncateToggle(target, toggle) {
	if(target.scrollHeight > target.clientHeight) {
		toggle.classList.remove('content-hidden');
	}
	else {
		toggle.classList.add('content-hidden');
	}
}

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}