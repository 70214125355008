jQuery(function(){
	// sticky navigation
	const header = document.querySelector('.header');
	const body = document.querySelector('body');
	if(header != null) {
		const height = header.offsetHeight;
		stickyNavigation(header, body, height);

		window.addEventListener('scroll', function() {
			stickyNavigation(header, body, height);
		});
	}
});

var lastScrollTop = 0;

function stickyNavigation(header, body, headerHeight) {
	const st = window.pageYOffset || document.documentElement.scrollTop;
	if (st > lastScrollTop && (window.scrollY >= headerHeight)) {
		// hide header
		header.classList.add('header--fixed');
		body.classList.remove('scrolled-header-wrapper');

		// close dropdowns
		const openedDropdowns = document.querySelectorAll('.dropdown--hover');
		openedDropdowns.forEach(dropdown => dropdown.classList.remove('dropdown--hover'));
		
	} else {
		// show header
		header.classList.remove('header--fixed');
		body.classList.add('scrolled-header-wrapper');
	}

	if(window.scrollY >= headerHeight) {
		header.classList.add('header--scrolled');
	}
	else {
		header.classList.remove('header--scrolled');
	}

	lastScrollTop = st <= 0 ? 0 : st;
}
