jQuery(function() {
	let pageLoaded = false;
	const body = $('body'),
		mainNav = $('.main-nav'),
		dropdowns = $('.dropdown'),
		dropdownTriggers = $('.dropdown__trig'),
		subdropdownTriggers = $('.subdropdown__trig'),
		menuTrigger = $('.menu-trigger');
	
	const searchTrigger = $('.dropdown__trig--search');
    const searchDropdown = $('.dropdown-menu--search');
    const searchInput = $('.dropdown-menu--search .search__input');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		hideDropdowns();
		hideClickDropdowns();
		body.toggleClass('body--menu-open');
	});

	searchTrigger.on('click', (e) => {
        e.preventDefault();
        if (!searchDropdown.length || !searchInput.length || searchDropdown.hasClass('dropdown--open')) return;

        window.setTimeout(function () {
            searchInput.get(0).focus();
        }, 0);
    });

	dropdownTriggers.each(function() {
		const trigger = $(this),
			elId = trigger.data('dropdown'),
			dropdownMenu = $('#' + elId),
			dropdown = $(this).closest('.dropdown');

		// desktop - hover
		trigger.on('mouseenter', function() {
			dropdown.addClass('dropdown--hover');
		});
		dropdown.on('mouseleave', function() {
			dropdown.removeClass('dropdown--hover');
		});

		// tab key
		trigger.on('focus', function() {
			dropdowns.removeClass('dropdown--hover');
			openDropdowns();
			dropdown.addClass('dropdown--hover');
		});
		dropdown.on('focus', function() {
			openDropdowns();
			dropdown.addClass('dropdown--hover');
		});


		// mobile - click
		if(dropdownMenu.length) {
			trigger.on('click', function(e) {
				if(trigger.hasClass('dropdown__trig--click')) {
					e.preventDefault();
					const wasOpened = dropdownMenu.hasClass('dropdown--open');
					hideDropdowns();

					if(!wasOpened) {
						dropdownMenu.addClass('dropdown--open').slideDown(200);
						trigger.addClass('dropdown__trig--active');
					}
					//body.removeClass('body--menu-open');
				}
				else if(($(window).width() < 992)) {
					e.preventDefault();
					dropdownMenu.toggleClass('dropdown--open').slideToggle(200);
					trigger.toggleClass('dropdown__trig--active');
				}
			});
		}
	});

	subdropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('subdropdown'),
			subdropdownMenu = $('#' + elId);

		// mobile - click
		if(subdropdownMenu.length) {
			trigger.on('click', function(e) {
				if(($(window).width() < 992)) {
					e.preventDefault();
					subdropdownMenu.toggleClass('subdropdown--open').slideToggle(200);
					trigger.toggleClass('subdropdown__trig--active');
				}
			});
		}
	});



	// desktop - hover animation
	mainNav.on('mouseenter', function(e) {
		pageLoaded = true;
		openDropdowns();
	});
	mainNav.on('mouseover', function(e) {
		if(!pageLoaded) {
			pageLoaded = true;
			openDropdowns();
		}
		else {
			mainNav.off('mouseover');
		}
	});
	openDropdowns();
	mainNav.on('mouseleave', function(e) {
		closeDropdowns();
	});

	// mobile - dropdowns closing
	$(document).bind('click', function (e) {
		if (!$('.main-nav, .secondary-nav, .sub-nav, .x-layer').is(e.target) && $('.main-nav, .secondary-nav, .sub-nav, .x-layer').has(e.target).length === 0) {
			if($(window).width() < 992) {
				hideDropdowns();
			}
			hideClickDropdowns();
		}
	});

	function openDropdowns() {
		mainNav.addClass('dropdown--animated-in');
		mainNav.addClass('dropdown--mouse-in');
		setTimeout(function(){
			mainNav.removeClass('dropdown--animated-in');
		}, 500);
	}

	function closeDropdowns() {
		mainNav.removeClass('dropdown--animated-in');

		mainNav.addClass('dropdown--animated-out');
		setTimeout(function(){
			mainNav.removeClass('dropdown--animated-out');
		}, 1000);
		mainNav.removeClass('dropdown--mouse-in');
	}

	function hideDropdowns() {
		$('.dropdown-menu').removeClass('dropdown--open');
		$('.dropdown-menu--click').slideUp(200, function() {  });
		$('.dropdown__trig').removeClass('dropdown__trig--active');
	}
	function hideClickDropdowns() {
		$('.dropdown-menu--click').slideUp(200, function() { $('.dropdown-menu--click').removeClass('dropdown--open'); });
		$('.dropdown__trig--click').removeClass('dropdown__trig--active');
	}
 });