jQuery(function(){
	initGalleries('.glightbox');

	initModals('.modal-trigger');

	initCloseFM();
});

function initGalleries(selector) {
	const lightbox = GLightbox({
		selector: selector,
		loop: true,
		zoomable: false,
		openEffect: 'fade',
		closeEffect: 'fade',
	});
}

function initModals(selector) {
	let customSlideHTML = `<div class="gslide">
		<div class="gslide-inner-content">
			<div class="ginner-container">
				<div class="gslide-media">
				</div>
				<div class="gslide-description">
					<div class="gdesc-inner">
						<h4 class="gslide-title"></h4>
						<div class="gslide-desc"></div>
					</div>
				</div>
				<button class="modal__close" tabindex="2" aria-label="Close">
					<svg class="icon icon-close">
						<use xlink:href="/static/svg/svg-sprite.svg#icon-close" />
					</svg>
				</button>
			</div>
		</div>
	</div>`;

	const modalsTriggers = GLightbox({
		selector: selector,
		openEffect: 'fade',
		closeEffect: 'fade',
		skin: 'clean glightbox-modal',
		height: 'auto',
		slideHTML: customSlideHTML,
		closeButton: false
	});

	const modals = document.querySelectorAll('.modal');

	modals.forEach((modal) => {
		modal.modalParent = modal.parentElement;
	});
	modalsTriggers.on('open', () => {
		const floatingLabelInputs = document.querySelectorAll('.modal .float-label__input');
		initFloatingLabelInputs(floatingLabelInputs);
		modals.forEach((modal) => {
			modal.modalParent.removeChild(modal);
		});

		const closeBtns = document.querySelectorAll('.modal__close');
		if(!closeBtns.length) return;
		for (const closeBtn of closeBtns) {
			closeBtn.addEventListener('click', () => modalsTriggers.close());
		}

	});
	modalsTriggers.on('close', () => {
		modals.forEach((modal) => {
			modal.modalParent.appendChild(modal);
		});
	});
}

// Close Flash message
const initCloseFM = () => {
	Array.from(document.querySelectorAll('.flash-message__close')).forEach((closeBtn,i) => {
		closeBtn.addEventListener('click', () => {
			const fm = closeBtn.closest('.flash-message');
			fm.classList.add('flash-message--hidden');
		});
	});

	const flashMessages = document.querySelectorAll('.flash-message');
	if(flashMessages.length) {
		setTimeout(() => {
			Array.from(flashMessages).forEach((fm,i) => {
				if(fm.getAttribute('data-hide')) {
					fm.classList.add('flash-message--hidden');
				}
			});
		}, 5000);
	}
}
