(function () {
	const triggers = document.querySelectorAll(".clipboard-copy");
	if (!triggers.length) return;

	const isSecure = window.isSecureContext;
	for (const trigger of triggers) {
		trigger.addEventListener("click", () => {
			const toCopy = trigger.dataset.copy;

			if (isSecure) {
				copy(trigger, toCopy);
			} else {
				navigator.permissions
					.query({ name: "clipboard-write" })
					.then((result) => {
						if (result.state === "granted" || result.state === "prompt") {
							copy(trigger, toCopy);
						}
						else {
							trigger.classList.add('clipboard-copy--denied');
							setTimeout(() => trigger.classList.remove('clipboard-copy--denied'), 2000);
						}
					});
			}
		});
	}
})();

const copy = (trigger, toCopy) => {
	navigator.clipboard.writeText(toCopy).then(
		() => {
			trigger.classList.add('clipboard-copy--copied');
			setTimeout(() => trigger.classList.remove('clipboard-copy--copied'), 2000);
		},
		() => {
			trigger.classList.add('clipboard-copy--denied');
			setTimeout(() => trigger.classList.remove('clipboard-copy--denied'), 2000);
		}
	);
};
