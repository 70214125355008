jQuery(function(){
	linkToAnchor({'selector': '.to-anchor'});
	$('.teaser__head--anim').splitAnimText();

	initAnimSections();
});

// Trigger animations after scroll to viewport
function initAnimSections() {
	var sections = $('.to-anim'),
		visible = 0;

	visible = animSections(sections, visible);

	$(window).off('scroll.sections');
	$(window).on('scroll.sections', function () {
		visible = animSections(sections, visible);
	});
}

function animSections(sections, visible) {
	sections.each(function() {
		var section = $(this);
		if(section.is(':in-viewport') && !section.hasClass('anim')) {
			section.addClass('anim');
			visible++;
		}
		if(visible >= sections.length) {
			$(window).off('scroll.sections');
		}
	});

	return visible;
}

// Split animated text to lines
(function ($) {
	$.fn.splitAnimText = function (settings) {
		return this.each(function () {
			const textEl = this;
			const text = textEl.innerText || textEl.textContent;
			const words = text.trim().split(' ');
			if(!words.length) return;

			const wordsEls = '<span>'+words.join('</span> <span>')+'</span>';
			textEl.innerHTML = wordsEls;

			var line = 0;
			var top = 0;
			for (var i = 0; i < textEl.children.length; i++) {
				const word = textEl.children[i];
				if(word.offsetTop > top) {
					top = word.offsetTop;
					line++;
				}
				word.classList.add('line', 'line--'+line);
				word.innerHTML = '<span class="line__inner">'+word.innerHTML+'</span>';
			}
			
		});
	};
})(jQuery);

// Scroll to anchor

function linkToAnchor(settings) {

	settings = jQuery.extend({
		speed: 500
	}, settings);

	$('body').on('click', settings.selector, function (event) {
		const caller = $(this);
		const elementClick = $(caller).attr("href");
		if (elementClick.length > 1 && elementClick.charAt(0) == '#') {
			event.preventDefault();

			scrollToAnchor(elementClick, settings.speed);

			return false;
		}
	});
};

function scrollToAnchor(elementId, speed = 500) {
	const destination = $(elementId).offset().top;

	$("html:not(:animated),body:not(:animated)").animate(
		{scrollTop: destination},
		speed, function () {
			history.pushState(null, null, elementId);
		}
	);
}