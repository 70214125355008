window.addEventListener('load', function(){
	equalheight('.equal-height')
});
window.addEventListener('resize', function(){
	equalheight('.equal-height')
});
window.addEventListener('contentResize', function(){
	equalheight('.equal-height')
});

function setHeight(el, val) {
	if (typeof val === "function") val = val();
	if (typeof val === "string") el.style.height = val;
	else el.style.height = val + "px";
}

var equalheight = function(container){
	var currentTallest = 0,
		currentRowStart = undefined,
		rowDivs = new Array(),
		topPosition = 0;

	Array.from(document.querySelectorAll(container)).forEach((el,i) => {
		el.style.height = 'auto';
		topPosition = el.offsetTop;

		if(currentRowStart == undefined) {
			currentRowStart = topPosition;
		}
		
		if(currentRowStart != topPosition) { // new row
			for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				setHeight(rowDivs[currentDiv], currentTallest)
			}
			rowDivs.length = 0;
			currentRowStart = topPosition;
			currentTallest = parseFloat(getComputedStyle(el, null).height.replace("px", ""))
			rowDivs.push(el);
		} else { // same row
			rowDivs.push(el);
			currentTallest = (currentTallest < parseFloat(getComputedStyle(el, null).height.replace("px", ""))) ? (parseFloat(getComputedStyle(el, null).height.replace("px", ""))) : (currentTallest);
		}
	})

	for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
		setHeight(rowDivs[currentDiv], currentTallest)
	}
}