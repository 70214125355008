var sliders = [];

$(document).ready(function ($) {
	// Sliders - distinguish click / drag on links in slider
	var drag = false;
	const delta = 6;
	var startX,
		startY;

	$('.slider__panel a').on('mousedown', function(event) {
		drag = false;
		startX = event.pageX;
	  	startY = event.pageY;
	});

	$('.slider__panel a').on('mouseup', function(event) {
		const diffX = Math.abs(event.pageX - startX);
		const diffY = Math.abs(event.pageY - startY);
		if (diffX > delta || diffY > delta) {
		    drag = true;
		}
	});

	$('.slider__panel a').on('click ', function(e) {
		if(drag) {
			e.preventDefault();
		}
		drag = false;
	});


	// Sliders initialization
	if ($.fn.sudoSlider) {

		/* Tab slider - tab selection */
		$('.tab-slider').each(function() {
			const tabs = $(this).find('.tab-slider__link');
			if(!tabs.length) return;

			tabs.on('click', function() {
				tabs.removeClass('tab-nav__link--selected');
				$(this).addClass('tab-nav__link--selected');
			});
		});

		/* Generic item slider */
		const itemSliders = $('.item-slider');
		initItemSliders(itemSliders);
	}

});

function initItemSliders(items) {
	if ($.fn.sudoSlider) {
		/* Generic item slider */
		items.each(function (index) {
			createSlider($(this), index);
		});

		$(window).on("resizeEnd focus", function () {
			for (var sliderId in sliders) {
				if (!sliders.hasOwnProperty(sliderId))
					continue;

				const range = getRange();

				if ((typeof sliders[sliderId]['slider'] === 'undefined')) {
					if(sliders[sliderId]['enabled-'+range]) {
						initSlider(sliderId);
					}
				}
				if ((typeof sliders[sliderId]['slider'] === 'undefined')) {
					continue;
				}

				const attributes = ['slides', 'move', 'responsive'];
				const sliderOptions = ['slideCount', 'moveCount', 'responsive'];

				attributes.forEach(function(attr, i) {
					var sliderOption = sliderOptions[i],
						currentValue = sliders[sliderId]['slider'].getOption(sliderOption);

					const rangedAttr = attr + '-' + range,
					rangedAttrValue = sliders[sliderId][rangedAttr];

					if (currentValue != rangedAttrValue) {
						sliders[sliderId]['slider'].setOption(sliderOption, rangedAttrValue);
					}
				});

				if (!sliders[sliderId]['responsive-'+range]) {
					sliders[sliderId]['slider'].adjust();	
					sliders[sliderId]['panels'].css('width', '');	
				}
				//sliders[sliderId]['slider'].setOption('autowidth', !sliders[sliderId]['responsive-'+range]);

				if(!sliders[sliderId]['enabled-'+range]) {
					destroySlider(sliderId);
				}

				window.dispatchEvent(new Event('contentResize'));
			}
		}).resize();
	}
}

function createSlider(el, index) {
	const sliderId = 'itemSlider'+index,
		sliderWrapper = el,
		controlsClass = '.' + el.data('controls'),
		controls = $(controlsClass),
		loadMore = $(el.data('load-more')),
		currentRange = getRange(),
		panels = el.find('.slider__panel');

	sliders[sliderId] = [];

	sliders[sliderId]['element'] = el;
	sliders[sliderId]['controls'] = controls;
	sliders[sliderId]['controls-class'] = controlsClass;
	sliders[sliderId]['controls-fade'] = el.data('controls-fade') ? el.data('controls-fade') : false;
	sliders[sliderId]['load-more'] = loadMore;
	sliders[sliderId]['initial-slide'] = initSlide = el.data('initial-slide');

	const attributes = ['slides', 'move', 'enabled', 'responsive'];
	const attrDefaultValues = ['1', '1', true, true];

	const ranges = ['xl', 'lg', 'md', 'sm', 'xs'];
	
	attributes.forEach(function(attr, i) {
		var currentValue = attrDefaultValues[i];

		ranges.forEach(function(range, j) {
			var defaultValue = currentValue;

			// e.g. sliders[sliderId]['slides-lg'] = el.data('slides-lg') ? el.data('slides-lg') : sliders[sliderId]['slides-xl'];
			sliders[sliderId][attr+'-'+range] = typeof el.data(attr+'-'+range) !== 'undefined' ? el.data(attr+'-'+range) : defaultValue;

			currentValue = sliders[sliderId][attr+'-'+range];
		});
	});

	sliders[sliderId]['panels'] = panels;

	if(typeof loadMore != 'undefined') {
		loadMore.on('click', function() {
			sliders[sliderId]['panels'].slideDown('fast');
			loadMore.hide();
		})
	}

	if(sliders[sliderId]['enabled-'+currentRange]) {
		initSlider(sliderId);
	}
	else {
		enableLoadMore(sliderId);
	}
}

function destroySlider(sliderId) {
	sliders[sliderId]['slider'].goToSlide('first');
	sliders[sliderId]['slider'].destroy();
	sliders[sliderId]['slider'] = undefined;

	sliders[sliderId]['element'].removeClass('item-slider--active');

	enableLoadMore(sliderId);
}

function enableLoadMore(sliderId) {
	sliders[sliderId]['controls'].hide();

	const loadMore = sliders[sliderId]['load-more'],
		range = getRange(),
		slideCount = sliders[sliderId]['slides-' + range];
	if(typeof loadMore != 'undefined') {
		loadMore.show();
		sliders[sliderId]['panels'].slice(slideCount).hide();
	}
}

function initSlider(sliderId) {
	const panelCount = sliders[sliderId]['panels'].length,
		range = getRange(),
		slideRange = 'slides-' + range,
		moveRange = 'move-' + range,
		responsiveRange = 'responsive-' + range,
		slidesCount = sliders[sliderId][slideRange],
		moveCount = sliders[sliderId][moveRange],
		responsive = sliders[sliderId][responsiveRange],
		controls = sliders[sliderId]['controls'],
		loadMore = sliders[sliderId]['load-more'],
		el = sliders[sliderId]['element'],
		initSlide = sliders[sliderId]['initial-slide'];

	if (panelCount > slidesCount) {
		controls.show();

		el.addClass('item-slider--active');

		sliders[sliderId]['slider'] = el.find('.slider').sudoSlider({
			//effect: 'fade',
			vertical: false,
			auto: false,
			autowidth: false,
			continuous: false,
			prevNext: false,
			numeric: false,
			controlsFade: sliders[sliderId]['controls-fade'],
			slideCount: slidesCount,
			moveCount: moveCount,
			speed: 400,
			responsive: responsive,
			touch: true,
			customLink: sliders[sliderId]['controls-class'],
			startSlide: initSlide
		});

		controls.show();
		//initControls(sliders[sliderId]['slider'], controls);
	}
	else {
		controls.hide();
		el.removeClass('item-slider--active');
	}

	if(typeof loadMore != 'undefined') {
		loadMore.hide();
	}

	window.dispatchEvent(new Event('resize'));
}

function getRange() {
	const width = $(window).width();

	if (width >= 1200) {
		return 'xl';
	} else if (width > 992) { 
		return 'lg';
	} else if (width > 768) { 
		return 'md';
	} else if (width > 576) { 
		return 'sm';
	} else {
		return 'xs';
	}
}

function initControls(slider, controls) {
	if(!controls.length) return;

	const prev = controls.find('.slider-nav__prev'),
		next = controls.find('.slider-nav__next');

	if(!prev.length || !next.length) return;

	prev.on('click', function() {
		slider.goToSlide("prev");
	});
	next.on('click', function() {
		slider.goToSlide("next");
	});
}