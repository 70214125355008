
(function() {
	const tabbed = document.querySelectorAll('.tabs');

	Array.prototype.forEach.call(tabbed, (tabbed, i) => {
		const tablist = tabbed.querySelector('.tab-nav__list');
		const tabs = tablist.querySelectorAll('.tab-nav__link');
		const panels = tabbed.querySelectorAll('.tab');

		// The tab switching function, make the active tab focusable by the user (Tab key)
		const switchTab = (oldTab, newTab) => {
			newTab.focus();
			newTab.removeAttribute('tabindex');
			newTab.setAttribute('aria-selected', 'true');
			newTab.classList.add('tab-nav__link--selected');

			oldTab.removeAttribute('aria-selected');
			oldTab.setAttribute('tabindex', '-1');
			oldTab.classList.remove('tab-nav__link--selected');

			let index = Array.prototype.indexOf.call(tabs, newTab);
			let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);

			panels[oldIndex].hidden = true;
			panels[oldIndex].classList.remove('tab--selected');

			panels[index].hidden = false;
			panels[index].classList.add('tab--selected');
		}
		
		tablist.setAttribute('role', 'tablist');
		
		// Add semantics and remove user focusability for each tab
		Array.prototype.forEach.call(tabs, (tab, i) => {
			tab.setAttribute('role', 'tab');
			tab.setAttribute('id', 'tab' + (i + 1));
			tab.setAttribute('tabindex', '-1');
			tab.parentNode.setAttribute('role', 'presentation');
			
			// Handle clicking of tabs for mouse users
			tab.addEventListener('click', e => {
			e.preventDefault();
			let currentTab = tablist.querySelector('[aria-selected]');
			if (e.currentTarget !== currentTab) {
				switchTab(currentTab, e.currentTarget);
				window.dispatchEvent(new Event('contentResize'));
			}
			});
			
			// Handle keydown events for keyboard users
			tab.addEventListener('keydown', e => {
			let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
			let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
			if (dir !== null) {
				e.preventDefault();
				dir === 'down' ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
			}
			});
		});
		
		// Add tab panel semantics and hide them all
		Array.prototype.forEach.call(panels, (panel, i) => {
			panel.setAttribute('role', 'tabpanel');
			panel.setAttribute('tabindex', '-1');
			panel.classList.remove('tab--selected');
			let id = panel.getAttribute('id');
			panel.setAttribute('aria-labelledby', tabs[i].id);
			panel.hidden = true; 
		});
		
		// Initially activate the first tab and reveal the first tab panel
		tabs[0].removeAttribute('tabindex');
		tabs[0].setAttribute('aria-selected', 'true');
		tabs[0].classList.add('tab-nav__link--selected');
		panels[0].hidden = false;
	});
})();