//OpenLayers
jQuery(function(){
	if($('.olmap').length) {
		const maps = $('.olmap').olMap();
	}
});


(function($) {
    $.fn.olMap = function() {
        return this.each(function(i) {
            const mapEl = $(this),
            	mapElId = 'olmap-' + i;
			const args = {
				zoom: 17,
				markerIcon: '/static/images/marker.png',
				centermarkerIcon: true, //set true if center of the marker should be centered to point
				grayScale: true,
				scrollWheel: false
			};
			mapEl.attr('id', mapElId);

			const pointEls = mapEl.get(0).querySelectorAll('.map__point');
			const points = [];

			if(pointEls.length) {
				pointEls.forEach((pointEl) => {
					const point = {
						lat: parseFloat(pointEl.getAttribute('data-lat')),
						lng: parseFloat(pointEl.getAttribute('data-lng'))
					}
					const popup = pointEl.querySelector('.map__popup');
					if(popup) {
						point.popup = popup.innerHTML;
					}
					if(point.lat && point.lng) {
						points.push(point);
					}
				});
			}

			if(points.length) {
				//create open street map layer
				const osmLayer = new ol.layer.Tile({
					source: new ol.source.OSM()
				});

				//apply grayscale
				if(args.grayScale) {
					osmLayer.on('postcompose', function(event) {
						greyscale(event.context);
				   });
				}

				//create markers for each point
				if(args.markerIcon) {
					var markerStyle = {
						crossOrigin: 'anonymous',
						src: args.markerIcon
					};
					markerStyle.anchor = [0.5, 1];
					markerStyle.anchorXUnits = 'fraction';
					markerStyle.anchorYUnits = 'fraction';

				}

				const vectorSource = new ol.source.Vector({
					features: []
				});

				points.forEach((point) => {
					const marker = new ol.Feature({
						geometry: new ol.geom.Point(
						  ol.proj.fromLonLat([point.lng, point.lat])
						),
					});
					if(markerStyle != undefined) {
						marker.setStyle(new ol.style.Style({
							image: new ol.style.Icon((markerStyle))
						}));
					}
					vectorSource.addFeature(marker);
				});

				const markerVectorLayer = new ol.layer.Vector({
					source: vectorSource,
				});

				//create view
				const view = new ol.View({
					center: ol.proj.fromLonLat([points[0].lng, points[0].lat]),
					zoom: args.zoom
				});
				
				//create map
				const map = new ol.Map({
					target: mapElId,
					layers: [
					  osmLayer,
					  markerVectorLayer
					],
					view: view,
					controls: ol.control.defaults({
						rotate: false,
						attribution: false
					}),
					interactions: ol.interaction.defaults({mouseWheelZoom: args.scrollWheel}),
				});

				//fit view to markers
				if(points.length > 1) {
					view.fit(vectorSource.getExtent(), {padding: [20, 20, 20, 20]});
				}

				$(mapEl).on('resize', function() {
					setTimeout( function() {
						map.updateSize();
					}, 200);
				});
			}

        });
    };
}(jQuery));


function olMap() {

}

//grayscale to every pixel in canvas
function greyscale(context) {

	const canvas = context.canvas;
	const width = canvas.width;
	const height = canvas.height;

	const imageData = context.getImageData(0, 0, width, height);
	const data = imageData.data;

	for (i = 0; i < data.length; i += 4){
		let r = data[i];
		let g = data[i + 1];
		let b = data[i + 2];
		// CIE luminance for the RGB
		let v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
		// Show white color instead of black color while loading new tiles:
		if(v === 0.0) v=255.0;  
		data[i+0] = v; // Red
		data[i+1] = v; // Green
		data[i+2] = v; // Blue
		data[i+3] = 255; // Alpha
	}
	context.putImageData(imageData,0,0);
}