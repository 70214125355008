$(document).ready(function ($) {
	$('.sh-trig, .toggle-more').toggler();
	$('.show-more').toggler({
		hideTrigger: true
	});
	$('.accordion__trigger').toggler({
		slide: true
	});
	$('.sh-trig-to-trigger').toggler({
		slide: true,
		scrollToTrigger: true,
		offsetScroll: -200
	});
});

function scrollToContent(content, offsetScroll) {
	var offset = 0;
	if (offsetScroll) {
		offset = offsetScroll;
	}
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: $(content).offset().top + offset}, 500, function () {});
}

function scrollToTrigger(trigger, offsetScroll) {
	var offset = 0;
	if (offsetScroll) {
		offset = offsetScroll + 200;
	}
	const top = $(trigger.data('trigger')).offset().top + offset;
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: top}, 500, function () {});
}

(function ($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			scrollToTrigger: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false,
			checkboxReverse: false
		}, settings);

		$(this).each(function () {

			var trigger = $(this),
					content = $(trigger.data('content'));

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					var checked = $(this).is(':checked');
					if(settings.checkboxReverse) checked = !checked;
					if (checked) {
						content.fadeIn(settings.showSpeed);
					} else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					if (trigger.is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				trigger.on('click', function () {
					console.log(content);
					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent(content, settings.offsetScroll);
								}
							});
						} else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent(content, settings.offsetScroll);
								}
							});
						}

						$(this).addClass('active').find('.sh-trig-text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					} else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToTrigger) {
									scrollToTrigger(trigger, settings.offsetScroll);
								}
							});
						} else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToTrigger) {
									scrollToTrigger(trigger, settings.offsetScroll);
								}
							});
						}
						$(this).removeClass('active').find('.sh-trig-text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					return false;
				});

				window.dispatchEvent(new Event('contentResize'));
			}
			// Hide on document click
			if (settings.hideOnDocumentClick) {
				$(document).bind('click', function (e) {
					if (!$(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) {
						content.fadeOut();
					}
				});
			}
			// Escape button
			if (settings.hideOnEscape) {
				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						content.fadeOut();
					}
				});
			}
		});
	};
})(jQuery);
