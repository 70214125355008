const scrollSpyLinks = document.querySelectorAll(".scrollspy__link");

window.addEventListener("scroll", event => {
  const fromTop = window.scrollY;

  scrollSpyLinks.forEach(link => {
    const section = document.querySelector(link.hash),
		sectionOffsetTop = section.offsetTop - 150;
    if (
      sectionOffsetTop <= fromTop &&
      sectionOffsetTop + section.offsetHeight > fromTop
    ) {
      link.classList.add('scrollspy__link--selected');
    } else {
      link.classList.remove('scrollspy__link--selected');
    }
  });
});

